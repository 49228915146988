<template>
  <div id="app">
    <div class="app-header">
      <new-index-header />
    </div>
    <div
      class="app-main"
      :class="footerLayoutEunm[footerType]"
    >
      <!--      <div class="main_bg">-->
      <!--        <div class="main_user_info">-->
      <!--          <div class="user_info">-->
      <!--            <el-upload-->
      <!--              class="avatar-uploader"-->
      <!--              action=""-->
      <!--              :show-file-list="false"-->
      <!--              :on-success="handleAvatarSuccess"-->
      <!--              :before-upload="beforeAvatarUpload"-->
      <!--            >-->
      <!--              <img-->
      <!--                :src="data.userInfo.headImg"-->
      <!--                alt="加载中..."-->
      <!--                title="点击更换头像"-->
      <!--              >-->
      <!--              <div class="head_img_tip">-->
      <!--                更换头像-->
      <!--              </div>-->
      <!--            </el-upload>-->
      <!--            <div class="user_msg">-->
      <!--              <div class="title">-->
      <!--                <span>{{ data.userInfo.nickname }}</span>-->
      <!--                <NuxtLink-->
      <!--                  v-if="data.userInfo.isVip"-->
      <!--                  to="/vip"-->
      <!--                >-->
      <!--                  <img-->
      <!--                    src="@/assets/pc/icon_vip.png"-->
      <!--                    alt=""-->
      <!--                  >-->
      <!--                </NuxtLink>-->
      <!--              </div>-->
      <!--              <span>{{ userPhone }}</span>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="user_course_msg">-->
      <!--            <div class="user_course_msg_box">-->
      <!--              <div class="user_course_msg_num">-->
      <!--                {{ opts.componentInfo.alreadyLearnCourseNum }}-->
      <!--              </div>-->
      <!--              <div class="user_course_msg_text">-->
      <!--                已学课程数-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="user_course_msg_box">-->
      <!--              <div class="user_course_msg_num">-->
      <!--                {{ opts.componentInfo.notesNum }}-->
      <!--              </div>-->
      <!--              <div class="user_course_msg_text">-->
      <!--                笔记数-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="user_course_msg_box">-->
      <!--              <div class="user_course_msg_num">-->
      <!--                {{ opts.componentInfo.collectionNum }}-->
      <!--              </div>-->
      <!--              <div class="user_course_msg_text">-->
      <!--                收藏数-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="user_course_msg_box">-->
      <!--              <div class="user_course_msg_num">-->
      <!--                {{ opts.componentInfo.alreadyGainCertNum }}-->
      <!--              </div>-->
      <!--              <div class="user_course_msg_text">-->
      <!--                已获证书-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="main w">
        <div class="side_box">
          <div class="account-info">
            <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <el-image
                style="width:100%;height:100%;"
                :src="data.userInfo.headImg"
                fit="cover"
                alt="加载中..."
                title="点击更换头像"
              />
              <div class="head_img_tip">
                更换头像
              </div>
            </el-upload>

            <div class="name">
              {{ data.userInfo.nickname }}
            </div>

            <div class="account-statistics">
              <div class="account-statistics-item hover1">
                <div class="item-num ">
                  {{ opts.componentInfo.totalCredit ? opts.componentInfo.totalCredit : 0 }}
                  <el-icon><caret-bottom /></el-icon>
                  <!--                  <div class="triangle1"></div>-->
                </div>

                <!--                <el-popover-->
                <!--                  placement="bottom"-->
                <!--                  width="180"-->
                <!--                  trigger="hover">-->
                <!--                  <template #reference>-->
                <!--                    <div class="item-num">-->
                <!--                      30-->
                <!--                      <div class="triangle1"></div>-->
                <!--                    </div>-->
                <!--                  </template>-->

                <!--                  <div class="time-content">-->
                <!--                    <div class="content-item">-->
                <!--                      <div class="point"></div>-->
                <!--                      <span class="year">2023</span>-->
                <!--                      <div class="triangle"></div>-->
                <!--                      <div>-->
                <!--                        <span class="time">30</span>-->
                <!--                        <span class="unit">学时</span>-->
                <!--                      </div>-->

                <!--                    </div>-->
                <!--                  </div>-->

                <!--                </el-popover>-->

                <div>已选总学时</div>
                <div class="hoverBox1">
                  <div>精品课程：</div>
                  <div class="yearTextBox">
                    <span class="year">{{ opts.componentInfo.currentYear }}年</span>
                    <el-icon class="iconBox">
                      <caret-right />
                    </el-icon>
                    <span>
                      <span class="time">{{ opts.componentInfo.currentYearTotalCredit }}</span>
                      学时</span>
                  </div>

                  <!-- <div>保育师课程：</div>
                  <div class="yearTextBox">
                    <span class="year">{{ opts.componentInfo.currentBatchNo }}</span>
                    <el-icon class="iconBox">
                      <caret-right />
                    </el-icon>
                    <span>
                      <span class="time">{{ opts.componentInfo.currentBatchNoTotalCredit }}</span>
                      学时</span>
                  </div> -->
                </div>
              </div>
              <div class="account-statistics-item hover2">
                <div class="item-num ">
                  {{ opts.componentInfo.finishCredit ? opts.componentInfo.finishCredit : 0 }}
                  <el-icon><caret-bottom /></el-icon>
                </div>

                <div>完成总学时</div>
                <div class="hoverBox2">
                  <div>精品课程：</div>
                  <div class="yearTextBox">
                    <span class="year">{{ opts.componentInfo.currentYear }}年</span>
                    <el-icon class="iconBox">
                      <caret-right />
                    </el-icon>
                    <span>
                      <span class="time">{{ opts.componentInfo.currentYearFinishCredit }}</span>
                      学时</span>
                  </div>

                  <div>保育师课程：</div>
                  <div class="yearTextBox">
                    <span class="year">{{ opts.componentInfo.currentBatchNo }}</span>
                    <el-icon class="iconBox">
                      <caret-right />
                    </el-icon>
                    <span>
                      <span class="time">{{ opts.componentInfo.currentBatchNoFinishCredit }}</span>
                      学时</span>
                  </div>
                </div>
              </div>
              <div class="account-statistics-item">
                <div class="item-num">
                  {{ opts.componentInfo.alreadyGainCertNum ? opts.componentInfo.alreadyGainCertNum : 0 }}
                </div>

                <div>获得证书</div>
              </div>
              <div class="account-statistics-item">
                <div class="item-num">
                  {{ opts.componentInfo.collectionNum ? opts.componentInfo.collectionNum : 0 }}
                </div>

                <div>收藏数</div>
              </div>
            </div>
          </div>
          <div class="side">
            <div
              v-for="(item, index) in data.sideList"
              :key="index"
              class="item"
              :class="{
                active: item.path === data.currentItem,
              }"
              @click="toLink(item.path)"
            >
              <!--              <span :class="`iconfont ${item.icon}`" />-->
              <template v-if="item.icon == 1">
                <img
                  class="img"
                  src="@/assets/images/new/icon/11.png"
                  alt=""
                >
                <img
                  class="active-img"
                  src="@/assets/images/new/icon/1.png"
                  alt=""
                >
              </template>
              <template v-else-if="item.icon == 2">
                <img
                  class="img"
                  src="@/assets/images/new/icon/21.png"
                  alt=""
                >
                <img
                  class="active-img"
                  src="@/assets/images/new/icon/2.png"
                  alt=""
                >
              </template>
              <template v-else-if="item.icon == 3">
                <img
                  class="img"
                  src="@/assets/images/new/icon/31.png"
                  alt=""
                >
                <img
                  class="active-img"
                  src="@/assets/images/new/icon/3.png"
                  alt=""
                >
              </template>
              <template v-else-if="item.icon == 4">
                <img
                  class="img"
                  src="@/assets/images/new/icon/41.png"
                  alt=""
                >
                <img
                  class="active-img"
                  src="@/assets/images/new/icon/4.png"
                  alt=""
                >
              </template>
              <template v-else-if="item.icon == 5">
                <img
                  class="img"
                  src="@/assets/images/new/icon/51.png"
                  alt=""
                >
                <img
                  class="active-img"
                  src="@/assets/images/new/icon/5.png"
                  alt=""
                >
              </template>
              <template v-else-if="item.icon == 6">
                <img
                  class="img"
                  src="@/assets/images/new/icon/61.png"
                  alt=""
                >
                <img
                  class="active-img"
                  src="@/assets/images/new/icon/6.png"
                  alt=""
                >
              </template>
              <template v-else-if="item.icon == 7">
                <img
                  class="img"
                  src="@/assets/images/new/icon/71.png"
                  alt=""
                >
                <img
                  class="active-img"
                  src="@/assets/images/new/icon/7.png"
                  alt=""
                >
              </template>
              <template v-else-if="item.icon == 8">
                <img
                  class="img"
                  src="@/assets/images/new/icon/81.png"
                  alt=""
                >
                <img
                  class="active-img"
                  src="@/assets/images/new/icon/8.png"
                  alt=""
                >
              </template>
              <template v-else-if="item.icon == 9">
                <img
                  class="img"
                  src="@/assets/images/new/icon/91.png"
                  alt=""
                >
                <img
                  class="active-img"
                  src="@/assets/images/new/icon/9.png"
                  alt=""
                >
              </template>
              <template v-else-if="item.icon == 10">
                <img
                  class="img"
                  src="@/assets/images/new/icon/101.png"
                  alt=""
                >
                <img
                  class="active-img"
                  src="@/assets/images/new/icon/10.png"
                  alt=""
                >
              </template>

              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="main_container">
          <slot />
        </div>
      </div>
      <new-bottomBg />
    </div>
    <div class="app-footer">
      <new-index-footer />
    </div>
    <back-top />
    <suspense-box />
  </div>
</template>

<script setup>
import { CaretBottom, CaretRight } from '@element-plus/icons-vue'
import { useUserStore } from '@/stores/user'
import { uploadAuthPic } from '@/api/upload'
import { ElMessage } from 'element-plus'
import cookie from '@/utils/cookies'
import { useLayoutStore } from '@/stores/layout'
import { userUpdateHeadImg, getUserGain } from '@/api/user'

const layoutStore = useLayoutStore()
const footerType = computed(() => layoutStore.footerType)
const footerLayoutEunm = ref({
  1: 'footer-style-one',
  2: 'footer-style-two',
  3: 'footer-style-three'
})
const store = useUserStore()
const { getUserView, getShopInfo, shopData } = store
getShopInfo()
const opts = reactive({
  componentInfo: {}
})
const route = useRoute()
const router = useRouter()
const data = reactive({
  currentItem: '',
  userInfo: computed(() => store.userView),
  sideList: [
    {
      name: '我的课程',
      path: '/account/course',
      icon: '6'
    },
    {
      name: '我的班级',
      path: '/account/grade',
      icon: '1'
    },
    {
      name: '我的试卷',
      path: '/account/paper',
      icon: '2'
    },
    {
      name: '我的笔记',
      path: '/account/note',
      icon: '7'
    },
    {
      name: '推广赚钱',
      path: '/account/money',
      icon: 'icon-m_note'
    },
    {
      name: '我的证书',
      path: '/account/certificate',
      icon: '5'
    },
    {
      name: '我的订单',
      path: '/account/order',
      icon: '3'
    },
    {
      name: '开票记录',
      path: '/account/invoice',
      icon: '10'
    },
    {
      name: '我的收藏',
      path: '/account/collect',
      icon: '9'
    },
    // {
    //   name: "优惠券",
    //   path: "/account/coupon",
    //   icon:'icon-m_coupon'
    // },
    {
      name: '消息通知',
      path: '/account/message',
      icon: '8'
    },
    {
      name: '资料与账号',
      path: '/account/info',
      icon: '4'
    }
  ]
})
onMounted(() => {
  if (!(shopData?.config?.enablePromoter)) {
    data.sideList.map((item, index) => {
      if (item.name.indexOf('推广赚钱') > -1) {
        data.sideList.splice(index, 1)
      }
    })
  }
  if (!(shopData?.config?.enabledInvoice)) {
    data.sideList.map((item, index) => {
      if (item.name.indexOf('开票记录') > -1) {
        data.sideList.splice(index, 1)
      }
    })
  }
})

const userPhone = computed({
  get: () => {
    const phone = data.userInfo.phone
    if (phone) return phone.substr(0, 3) + ' **** ' + phone.substr(7)
    else return ''
  }
})

watch(
  route, (newValue, oldValue) => {
    data.currentItem = route.path
  }
)

onMounted(async () => {
  opts.componentInfo = await getUserGain()
  data.currentItem = route.path
  getUserView(true)
})
const cookieData = cookie.getShop()
useHead({
  link: [
    { rel: 'icon', type: 'image/x-icon', href: cookieData.shopIcon }
  ]
})

const toLink = (path) => {
  router.push({ path })
}

const handleAvatarSuccess = (res, file) => {
  const formData = new FormData()
  formData.append('picFile', file.raw)
  uploadAuthPic(formData).then((res) => {
    if (res && res.data) {
      data.userInfo.headImg = res.data.fileUrl
      userUpdateHeadImg({
        headImg: res.data.fileUrl
      }).then(() => {
        getUserView(true)
      })
    }
  })
}
const beforeAvatarUpload = (file) => {
  const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isJPG) {
    ElMessage.error('Avatar picture must be JPG format!')
  }
  if (!isLt2M) {
    ElMessage.error('Avatar picture size can not exceed 2MB!')
  }
  return isJPG && isLt2M
}
</script>

<style lang="scss" scoped>
#app {
  position: relative;
  background-color: #f6f8fb;
}

.app-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
}
.user_course_msg{
  display: flex;
  align-items: center;
  .user_course_msg_box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    &:after{
      content: '';
      width: 54px;
      height: 0px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      transform: rotate(105deg);
      position: absolute;
      right: -60px;
    }
  }
  .user_course_msg_box:last-child:after{
    width: 0;
  }
  .user_course_msg_num{
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
  }
  .user_course_msg_text{
    color: rgba(255, 255, 255, 0.7);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 4px;
  }
  .user_course_msg_box+.user_course_msg_box{
    padding-left: 54px;

  }
}
.user_info {
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  color: #999;
  display: flex;
  align-items: center;
  .user_msg{
    margin-left: 20px;
    max-width: 240px;
  }
  .user_msg span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
  }
  .avatar-uploader img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      text-align: left;
      color: #FFFFFF;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 10px;
    }
  }
}
.app-main {
  min-height: 100vh;
  padding-top: 160px;
  background-color: #f6f8fb;
  .main_bg {
    height: 150px;
    background: url("@/assets/images/account_bg.png") no-repeat;
    background-size: cover;

  }
  .main_user_info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    margin: auto;
    height: 100%;
  }
  .main {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    .side_box {
      width: 200px;
      margin-right: 10px;
      .side {
        background: #fff;
        //border-radius: 10px;
        overflow: hidden;
        padding: 20px;
        .item+.item{
          margin-top: 18px;
        }
        .item {
          font-size: 16px;
          padding-left: 20px;
          display: flex;
          align-items: center;
          height: 50px;
          cursor: pointer;
          position: relative;
          .iconfont{
            font-size: 16px;
            margin-right: 8px;
          }
          img{
            width: 13px;
            height: 13px;
            margin-right: 5px;
          }
          .img{
            display: block;
          }
          .active-img{
            display: none;
          }
          &.active,&:hover {
            background: rgba(61, 127, 255, 0.1);
            border-radius: 4px;
            color: #3D7FFF;

            .img{
              display: none;
            }
            .active-img{
              display: block;
            }
          }
        }
      }

      .account-info{
        padding: 20px;
        background: #fff;
        margin-bottom: 20px;
        //border-radius: 10px;

        .head_img_tip {
          position: absolute;
          top: 0px;
          opacity: 0;
          width: 74px;
          height: 74px;
          color: #FFFFFF;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 60px;
          font-weight: 400;
          font-size: 12px;
          line-height: 74px;
          z-index: 2;
          text-align: center;
          transition: all .2s;
        }
        .avatar-uploader {
          width: 74px;
          height: 74px;
          border-radius: 60px;
          overflow: hidden;
          position: relative;
          margin: 0 auto;
          :deep(.el-upload){
            width: 74px;
            height: 74px;
          }
        }
        .avatar-uploader:hover {
          .head_img_tip {
            top: 0;
            opacity: 1;

          }
        }
        .name{
          font-size: 18px;
          line-height: 30px;
          font-weight: bold;
          text-align: center;
          margin: 12px 0;
        }

        .account-statistics{
          display: flex;
          flex-wrap: wrap;
          .account-statistics-item{
            position: relative;
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #666666;
            cursor: pointer;
            .item-num{
              color:#1671DA;
              font-size: 20px;
              line-height: 50px;
              font-weight: bold;
              display: flex;
              align-items: center;

              .triangle1 {
                margin-left: 5px;
                margin-top: 3px;
                width: 0;
                height: 0;
                border-top: 6px solid #1671DA;
                border-right: 6px solid transparent;
                border-left: 6px solid transparent;
              }

              &:hover{
                .triangle1{
                  transform: rotate(180deg);
                }
              }
            }
            .hoverBox1{
              display: none;
              position: absolute;
              height: 70px;
              width: 150px;
              bottom: -70px;
              left: 10px;
              padding: 10px;
              background: #FFFFFF;
              border-radius: 8px;
              border: 1px solid #ccc;
              z-index: 9;
              .yearTextBox{
                span{
                color: #b2b2b2;
                }
                .time{
                  color: rgb(50, 132, 218);
                }
                .year{
                  color: #000000;
                }

                .iconBox{
                  color: rgb(131, 183, 233);
                  margin: 0 2px;
                }
                background: linear-gradient(to right,rgba(234, 246, 255,1),#FFFFFF);
                border-radius: 8px;
                padding: 2px 6px;
                margin: 5px 0;
              }
            }
            .hoverBox2{
              display: none;
              position: absolute;
              height: 120px;
              width: 150px;
              bottom: -120px;
              right: 10px;
              padding: 10px;
              background: #FFFFFF;
              border-radius: 8px;
              border: 1px solid #ccc;
              z-index: 9;
              .yearTextBox{
                span{
                color: #b2b2b2;
                }
                .time{
                  color: rgb(50, 132, 218);
                }
                .year{
                  color: #000000;
                }

                .iconBox{
                  color: rgb(131, 183, 233);
                  margin: 0 2px;
                }
                background: linear-gradient(to right,rgba(234, 246, 255,1),#FFFFFF);
                border-radius: 8px;
                padding: 2px 6px;
                margin: 5px 0;
              }
            }
          }
          .hover1:hover{
            .hoverBox1{
              display: block;
            }
          }
          .hover2:hover{
            .hoverBox2{
              display: block;
            }
          }
        }
      }
    }
    .main_container {
      background: #fff;
      //border-radius: 10px;
      width: 990px;
      padding-bottom: 20px;
    }
  }
}

.time-content{

  .content-item{
    padding: 8px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    border-radius: 4px;

    .year{
      color:#000000;
    }

    .triangle {
      width: 0;
      height: 0;
      border-left: 5px solid #1671DA;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
    }

    .time{
      color: #1671DA;
      margin-right: 5px;
    }

    .unit{
      color:#888888;
    }

    .point{
      width: 6px;
      height: 6px;
      background: #89BCF7;
      border-radius: 50%;
    }

    &:hover{
      background: #F2F5FB;
    }
  }
}
</style>
